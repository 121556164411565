<!--
 * @Author: your name
 * @Date: 2021-08-01 13:21:04
 * @LastEditTime: 2021-09-25 13:34:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\userCenter\index.vue
-->
<template>
  <div class="apply flex-h flex-vc">
    <aside>
      <div class="title">历史生成记录</div>
      <div v-if="list.length == 0" style="margin: 10px; text-align: center">
        暂无生成记录
      </div>
      <ul>
        <el-scrollbar style="height: 100%">
          <li
            v-for="(item, index) in list"
            :key="index"
            @click="info(item.applyId)"
          >
            {{ $formatDate(item.applyTime) }}生成&nbsp;&nbsp;&nbsp;>>
          </li>
        </el-scrollbar>
      </ul>
    </aside>
    <div class="content">
      <img class="logo" src="@/assets/img/logo.png" />
      <div class="certificate1" v-if="affirm == 2">
        <h1>志愿者证书申请</h1>
        <div class="certificate1-box flex-h flex-vc">
          <img src="@/assets/img/zs1.png" />
          <certificate
            :name="volunteerName"
            :sex="sex"
            :number="certificateCode"
            :avatar="certificatePhoto"
            :birthday='birthday'
          />
        </div>
      </div>
      <div class="certificate2" v-if="affirm == 3">
        <h1>志愿者证书申请</h1>
        <div class="certificate2-box flex-h flex-vc">
          <img class="zs" src="@/assets/img/zs2.png" />
          <div class="box-right">
            <img class="qode" src="@/assets/img/qr-pub.jpg" />
            <p>
              请添加少艺委指定工作人员微信，协助您完成会员志愿者的认证流程。认证不通过将无法为您制作会员志愿者证书。
            </p>
          </div>
        </div>
      </div>
      <div v-if="affirm == 3 || affirm == 2">
        <div class="content-title">申请须知</div>
        <div class="content-desc">
          1.申请的证书为纸质版志愿者证书，由平台免费提供，不包邮，快递到付。<br />
          2.申请时请谨慎填写收货人信息。申请后，若证书未派发，则可修改收货人信息；若证书已派发，则不可修改收货人信息。
        </div>
        <div class="button" @click="(TYPE = 'add'), (dialogFormVisible = true)">
          提交申请
        </div>
      </div>
      <div class="certificate3" v-if="affirm == 1">
       <img src="@/assets/img/zczs.jpg" />
      </div>
      <div v-if="affirm == 1" class="button" @click="show = true">
          提交申请
        </div>
    </div>
    <el-dialog
      :title="ruleForm.applyId ? '' : '完善收货信息'"
      :visible.sync="dialogFormVisible"
      center
      width="550px"
      :modal-append-to-body="false"
      :append-to-body="true"
      @close="close"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        style="width: 450px"
        size="small"
      >
        <el-form-item label="证书申请时间" v-if="ruleForm.status >= 0">
          {{ $formatDate(ruleForm.applyTime) }}
        </el-form-item>
        <el-form-item
          label="状态"
          style="color: green"
          v-if="ruleForm.status == 1"
        >
          已派发
        </el-form-item>
        <el-form-item
          label="状态"
          style="color: orange"
          v-if="ruleForm.status == 3"
        >
          待审核
        </el-form-item>
        <el-form-item
          label="状态"
          style="color: blue"
          v-if="ruleForm.status == 0"
        >
          待派发
        </el-form-item>
        <el-form-item
          label="状态"
          style="color: red"
          v-if="ruleForm.status == 2"
        >
          已拒绝
        </el-form-item>
        <el-form-item label="拒绝原因" v-if="ruleForm.status == 2">
          {{ ruleForm.remark }}
        </el-form-item>
        <el-form-item label="收货人姓名" prop="receiverName">
          <el-input
            v-if="ruleForm.status == 3 || !ruleForm.applyId"
            v-model="ruleForm.receiverName"
            maxlength="10"
            show-word-limit
            clearable
            placeholder="收货人姓名"
          ></el-input>
          <span v-else>{{ ruleForm.receiverName }}</span>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input
            v-if="ruleForm.status == 3 || !ruleForm.applyId"
            v-model="ruleForm.phone"
            maxlength="11"
            show-word-limit
            clearable
            placeholder="联系电话"
          ></el-input>
          <span v-else>{{ ruleForm.phone }}</span>
        </el-form-item>
        <el-form-item label="邮寄地址" prop="address">
          <el-input
            v-if="ruleForm.status == 3 || !ruleForm.applyId"
            v-model="ruleForm.address"
            maxlength="40"
            show-word-limit
            clearable
            placeholder="请输入邮寄地址"
            type="textarea"
          ></el-input>
          <span v-else>{{ ruleForm.address }}</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          v-if="!ruleForm.applyId"
          type="primary"
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
        <el-button
          v-if="ruleForm.status == 3"
          type="primary"
          @click="updateForm('ruleForm')"
          >确认修改</el-button
        >
      </div>
    </el-dialog>
    <transition name="fade">
      <div class="poput" v-if="show">
        <div class="box">
          <div class="boxTitle">申请须知</div>
          <p>
            1、我们仅为会员志愿者和学生志愿者提供纸质版志愿者证书，如需纸质版志愿者证书，请申请成为会员志愿者或学生志愿者。
          </p>
          <p>
            2、根据《中华志愿者协会章程》，在第二届会员代表大会二次会议上，以无记名投票的方式调整了会费标准，会员志愿者会费200元/年(学生志愿者无会费)；
          </p>
          <p>3、会员志愿者有哪些权利？</p>
          <p>* 本协会的选举权、被选举权和表决权；</p>
          <p>* 参加本协会组织的有关活动；</p>
          <p>* 获得本协会服务的优先权；</p>
          <p>* 对本协会工作的知情权、建议权和监督权；</p>
          <p>* 向本协会推荐会员；</p>
          <p>* 入会自愿、退会自由。</p>
          <div class="qodeBox flex-h flex-vc">
            <img src="@/assets/img/qr-pub.jpg" />
            <span
              >若您还有疑问，请添加少艺委指定工作人员微信，协助您完成会员志愿者的申请流程。申请不通过将无法为您制作会员志愿者证书。</span
            >
          </div>
          <div class="buttons flex-h flex-vc flex-hc">
            <div class="button1" @click="show = false">我知道了</div>
            <div class="button2" @click="$router.push({name:'auth'})">确认申请</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import certificate from "@/components/certificate.vue";
import {
  certificateInfo,
  certificateStu,
  applyLogs,
  logsInfo,
  certificateUpdate,
} from "@/api/index";
const validatePhone = (rule, value, callback) => {
  if (!/^1\d{10}$/.test(value)) {
    callback(new Error("请输入正确格式手机号码"));
  } else {
    callback();
  }
};
export default {
  components: {
    certificate,
  },
  data() {
    return {
      dialogFormVisible: false,
      volunteerName: "",
      sex: "",
      certificateCode: "",
      certificatePhoto: "",
      birthday:'',
      ruleForm: {
        receiverName: "",
        phone: "",
        address: "",
      },
      list: [],
      rules: {
        receiverName: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            message: "请输入电话信息",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请输入邮寄地址", trigger: "blur" },
        ],
      },
      show: false,
    };
  },
  methods: {
    close() {
      this.ruleForm = {
        receiverName: "",
        phone: "",
        address: "",
      };
    },
    info(id) {
      logsInfo({
        id,
      }).then((res) => {
        console.log(res);
        this.ruleForm = res.data;
        this.dialogFormVisible = true;
      });
    },
    getInfo() {
      certificateInfo().then((res) => {
        let data = res.data;
        this.volunteerName = data.volunteerName;
        this.sex = data.sex;
        this.certificateCode = data.certificateCode;
        this.certificatePhoto = data.certificatePhoto;
        this.birthday=this.$formatDate(data.birthday*1000, "{y}/{m}/{d}")
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          certificateStu(this.ruleForm).then((res) => {
            this.dialogFormVisible = false;
            this.$alert(
              "我们将在20个工作日内为您寄出志愿者证书，请注意查收~",
              "提交成功",
              {
                confirmButtonText: "确定",
              }
            );
            this.getList();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          certificateUpdate(this.ruleForm).then((res) => {
            this.dialogFormVisible = false;
            this.$alert(
              "我们将在20个工作日内为您寄出志愿者证书，请注意查收~",
              "提交成功",
              {
                confirmButtonText: "确定",
              }
            );
            this.getList();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getList() {
      applyLogs().then((res) => {
        this.list = res.data;
      });
    },
  },
  computed: {
    affirm() {
      return this.$store.state.affirm;
    },
  },
  created() {
    this.getInfo();
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
li {
  list-style-type: none;
}
.apply {
  height: 100%;
  aside {
    width: 210px;
    height: 100%;
    box-sizing: border-box;
    padding-top: 25px;
    .title {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ff6e6e;
      text-align: center;
      margin-top: 40px;
    }
    ul {
      height: 500px;
      margin-top: 25px;
      li {
        text-align: center;
        font-size: 13px;
        font-family: MicrosoftYaHei;
        color: #6b6a6a;
        line-height: 30px;
        cursor: pointer;
        &:hover {
          color: rgba(255, 110, 110, 1);
        }
      }
    }
  }
  .content {
    width: 628px;
    height: 642px;
    background: #fff6f6;
    border-radius: 23px;
    box-sizing: border-box;
    padding: 25px;
    .logo {
      width: 140px;
    }
    h1 {
      font-size: 25px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #010101;
      letter-spacing: 2px;
      margin-top: 15px;
      text-align: center;
    }
    .certificate1 {
      .certificate1-box {
        margin-top: 25px;
        img {
          &:nth-of-type(1) {
            width: 178px;
            height: 264px;
            margin-right: 10px;
          }
          &:nth-of-type(2) {
            width: 401px;
            height: 289px;
          }
        }
      }
    }
    .certificate2 {
      .certificate2-box {
        margin-top: 25px;
        .zs {
          width: 178px;
          height: 264px;
          margin-right: 10px;
        }
        .box-right {
          width: 260px;
          margin-left: 35px;
          .qode {
            width: 133px;
            height: 132px;
            display: block;
            margin: 0 auto;
          }
          p {
            margin-top: 25px;
            text-align: center;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: #636363;
            line-height: 16px;
          }
        }
      }
    }
    .certificate3{
      img{
        width: 545px;
        margin: 20px auto;
      }
    }
    .content-title {
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ff6e6e;
      margin-top: 15px;
    }
    .content-desc {
      margin-top: 15px;
      height: 106px;
      background: #ffffff;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 26px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #636363;
      line-height: 22px;
    }
    .button {
      width: 170px;
      height: 37px;
      background: #ff6e6e;
      border-radius: 24px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 37px;
      cursor: pointer;
      margin: 0 auto;
      margin-top: 15px;
    }
  }
  .poput {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
    .box {
      width: 645px;
      height: 620px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 9px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 36px 70px 23px 70px;
      .boxTitle {
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #555555;
        text-align: center;
        margin-bottom: 36px;
      }
      p {
        margin-bottom: 15px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #716f6f;
        line-height: 19px;
      }
      .qodeBox {
        margin-top: 20px;
        img {
          width: 88px;
        }
        span {
          width: 448px;
          height: 32px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #efa954;
          line-height: 16px;
          margin-left: 22px;
        }
      }
    }
    .buttons {
      margin-top: 30px;
      .button1 {
        width: 112px;
        height: 32px;
        border-radius: 24px;
        border: 1px solid #ff6e6e;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ff6e6e;
        line-height: 32px;
        margin-right: 20px;
      }
      .button2 {
        width: 112px;
        height: 32px;
        background: #ff6e6e;
        border-radius: 24px;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 32px;
        margin-left: 20px;
      }
    }
  }
}
</style>