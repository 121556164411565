<!--
 * @Author: your name
 * @Date: 2021-08-05 17:07:31
 * @LastEditTime: 2021-09-22 10:59:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\components\certificate.vue
-->
<template>
  <div class="certificate">
    <img class="head" :src="avatar" />
    <p>{{ name }}</p>
    <p>{{ sex == 1 ? "男" : sex == 2 ? "女" : "" }}</p>
    <p>{{ birthday }}</p>
    <p>{{ number }}</p>
  </div>
</template>
<script>
export default {
  props: ["avatar", "name", "sex", "number","birthday"],
};
</script>
<style lang="scss" scoped>
.certificate {
  width: 354px;
  height: 256px;
  background: url("../assets/img/zsBg.png") no-repeat center center / 100% 100%;
  position: relative;
  .head {
    width: 75px;
    height: 105px;
    position: absolute;
    top: 75px;
    left: 22px;
  }
  p {
    
    font-size: 11px;
    font-family: MicrosoftYaHei;
    color: #000000;
    position: absolute;
    &:nth-of-type(1) {
      top: 80px;
      left: 132px;
    }
    &:nth-of-type(2) {
     top: 115px;
      left: 132px;
    }
    &:nth-of-type(3) {
      top: 150px;
      left: 132px;
    }
     &:nth-of-type(4) {
      top: 185px;
      left: 132px;
    }
  }
}
</style>